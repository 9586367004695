<template>
    <div>
        <div class="tweets-image">
            <img src="../../assets/img/darenxiu3.jpg"/>
        </div>
        <div class="lct-content" style="overflow: hidden">
            <div class="tweets-dropdown">
                <el-dropdown size="medium" split-button type="primary" @command="changeDropdown">
                    {{ type_name }}
                    <el-dropdown-menu slot="dropdown">
                        <el-dropdown-item v-for="(item,index) in options" :command="index">{{ item.label }}</el-dropdown-item>
                    </el-dropdown-menu>
                </el-dropdown>
            </div>
            <div class="lct-main">
                <div class="home-hotCourse-item" v-for="(item,index) in lists" @click="toPath(item)" :key="item.id">
                    <div class="home-hotCourse-img">
                        <img :src="item.img_url"/>
                        <el-image
                                style="width: 100%; height: 100%"
                                :src="item.img_url" fit="cover"></el-image>
                    </div>
                    <div class="home-hotCourse-title">
                        {{ item.title }}
                    </div>
                    <div class="home-hotCourse-hospital">
                        {{ item.intro }}
                    </div>
                    <div class="home-hotCourse-price">
                        <div class="course-item-price-author">{{ item.author }}</div>
                        <div class="course-item-price-company">
                            {{ item.company }}
                        </div>
                    </div>
                </div>


                <no-data v-if="lists.length<=0"/>
            </div>
            <div class="pagination" v-if="lists.length>0">
                <el-pagination
                        background
                        layout="prev, pager, next"
                        :total="total"
                        :current-page="current"
                        @current-change="currentChange">
                </el-pagination>
            </div>
        </div>



        <el-dialog :visible.sync="alertVisible" title="学习联盟" width="400px" :footer="null">
            <div class="modal-txt">
                <vue-qr class="vue-qr" :margin="0" :text="`${baseUrl}/ysxxlm/index.html#/pages/baiji/tweets`" colorDark="#000" colorLight="#fff" :size="200"/>
                <p style="margin-top: 20px;">请先微信扫码完善个人信息</p>
            </div>
        </el-dialog>

    </div>
</template>

<script>
    import vueQr from 'vue-qr'
    import config from '@/api/url'
    export default {
        components: {
            vueQr
        },
        data() {
            return {
                alertVisible: false,
                baseUrl: config.baseUrl,
                type_name: '筛选',
                type_id: '',
                txt: '暂无相关内容~',
                options: [{
                    label: '全部',
                    value: ''
                },{
                    label: '尿路上皮癌',
                    value: 1
                }, {
                    label: '前列腺癌',
                    value: 2
                }, {
                    label: '肾癌',
                    value: 3
                }],
                current: 1,
                total: 0,
                lists: [],
            }
        },
        watch:{
            $route(to,from){
                if(to.query.code) {
                    setTimeout( ()=> {
                        this.getLoad();
                    },1500)
                }
            }
        },
        computed: {
            userInfo() {
                let userInfo = '';
                if(this.$store.state.userInfo && this.$store.state.userInfo != undefined){
                    userInfo = this.$store.state.userInfo
                }
                return userInfo
            },
        },
        mounted() {
            if(!this.userInfo || this.userInfo == undefined) {
                this.$store.commit('wxLogin',true);
                return false
            }
            this.getLoad()
        },
        methods: {
            getLoad() {
                this.$axios.post(this.apiUrl.is_alert, {
                    rank: this.userInfo.user.rank
                }).then(res => {
                    if(res.code == 1) {
                        if (res.data.is_alert == 2) {
                            this.alertVisible = true;
                            this.txt = '请先微信扫码完善个人信息'
                        }else {
                            this.getList()
                        }
                    }
                })
                this.$axios.post(this.apiUrl.recordAll, { type: 3 }).then(res => {})
            },
            getList() {
                this.$axios.post(this.apiUrl.SurgicalQueryList, {
                    type_id: this.type_id,
                    page: this.current
                }).then(res => {
                    this.lists = res.data.list;
                    this.total = res.data.allpage;
                })
            },
            currentChange(e) {
                document.documentElement.scrollTop = 0
                this.current = e;
                this.getList();
            },
            toPath(item) {
                const routerJump = this.$router.resolve({path: `/bza/operation/${item.id}`})
                window.open(routerJump.href, '_blank')
            },
            getQueryVariable(url, nu) {
                var temp = url.split('?')[1];
                var pram = new URLSearchParams('?' + temp);
                console.log(pram)
                return pram.get(nu);
            },
            changeDropdown(e) {
                this.type_name = this.options[e].label;
                this.type_id = this.options[e].value;
                this.current = 1;
                this.getList()
            }
        }
    }
</script>

<style scoped lang="scss">
    .tweets-image{
        text-align: center;
        background: #00052e;
        img {
            width: 100%;
            height: auto;
            vertical-align: top;
        }
    }
    .tweets-dropdown{
        width: 1200px;
        margin: 50px auto 0;
    }
    .actciles-item-info{
        display: flex;
        flex-direction: column;
        justify-content: space-between;
    }


    .home-hotCourse-item{
        width: 240px;
        /*height: 255px;*/
        border-radius: 5px;
        padding: 15px 15px 0;
        box-sizing: border-box;
        overflow: hidden;
        .home-hotCourse-img{
            height: 210px;
            position: relative;
            border-radius: 5px;
            overflow: hidden;
            img{
                width: 100%;
                height: auto;
            }
            .home-hotCourse-num{
                position: absolute;
                bottom: 0;
                right: 0;
                height: 28px;
                width: 145px;
                line-height: 28px;
                text-align: right;
                color: #fff;
                font-size: 14px;
                background: url("https://image.ysxxlm.cn/ysxxlm/pc/static/img/course-ombre@2x.png") no-repeat;
                background-size: 100% 100%;
                box-sizing: border-box;
                padding-right: 14px;
            }
            .home-hotCourse-vip{
                position: absolute;
                left: 0;
                top: 0;
                height: 28px;
                line-height: 26px;
                padding: 0 15px;
                background: #288cfb;
                color: #fff;
                font-size: 14px;
                border-bottom-right-radius: 5px;
            }
        }
        .home-hotCourse-title{
            margin-top: 10px;
            font-size: 16px;
            color: #090B11;
            display: -webkit-box;
            -webkit-box-orient: vertical;
            -webkit-line-clamp: 2;
            overflow: hidden;
            line-height: 25px;
            height: 50px;
            font-weight: 500;
        }
        .home-hotCourse-hospital{
            font-size: 14px;
            color: #858585;
            overflow:hidden;
            text-overflow:ellipsis;
            white-space:nowrap;
        }
        .home-hotCourse-price{
            font-size: 16px;
            color: #288CFB;
            font-weight: 600;
            display: flex;
            align-items: center;
            justify-content: space-between;
            span {
                margin-right: 5px;
            }
            .course-item-price-author{
                color: #858585;
                font-weight: normal;
                font-size: 12px;
                max-width: 120px;
                overflow: hidden;
                white-space: nowrap;
                text-overflow: ellipsis;
                flex: 1;
            }
            .course-item-price-l{
                /*flex: 1;*/
                text-align: right;
            }
            .course-item-price-r{
                padding: 0 2px;
                border: 1px solid #b1894e;
                border-radius: 8px;
                font-size: 10px;
                font-weight: 400;
                color: #b78842;
                display: -webkit-box;
                display: -webkit-flex;
                display: flex;
                -webkit-box-pack: center;
                -webkit-justify-content: center;
                justify-content: center;
                -webkit-box-align: center;
                -webkit-align-items: center;
                align-items: center;
                margin-left: 8px;
                height: 16px;
            }
            .course-item-price-company{
                font-size: 12px;
                color: #858585;
                overflow:hidden;
                text-overflow:ellipsis;
                white-space:nowrap;
                font-weight: normal;
            }
        }
        &:hover{
            box-shadow: 0 0 20px #eee;
            cursor: pointer;
            background: #fff;
        }
    }
</style>
